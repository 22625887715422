// Landing Page Data
export const landingPageData = {
  coming_soon: "Coming Soon!",
  heading: "Our new website is on its way.",
  notify: {
    text: "Get notified when we launch.",
    button: "Notify Me",
    placeholder: "Enter Your Email Here...",
  },
};

// Social Media List
export const socialMediaData = [
  {
    icon: "fab fa-instagram",
    link: "https://www.instagram.com/harikrushna_pattani_associates/",
    name: "Instagram",
    class: "social-icons-instagram",
  },
  {
    icon: "fab fa-youtube",
    link: "https://youtube.com/@harikrushnapattani_architects",
    name: "Youtube",
    class: "social-icons-youtube",
  },
  {
    icon: "fab fa-linkedin",
    link: "https://www.linkedin.com/in/harikrushna-pattani-009611105/",
    name: "LinkedIn",
    class: "social-icons-linkedin",
  },
];

// About Us Data
export const aboutData = {
  desc: "Harikrushna Pattani & Associates is a dedicated team of experienced architects and designers known for seamlessly integrating natural elements into functional, beautiful spaces.",
  cards: [
    {
      icon: "far fa-thumbs-up",
      title: "Why Choose Us",
      desc: "Choose us for expertise, client focus, sustainability, and project excellence. Your vision, our expertise.",
    },
    {
      icon: "far fa-paper-plane",
      title: "Our Mission",
      desc: "Our mission is to deliver tailored design solutions while prioritizing client needs and sustainability.",
    },
    {
      icon: "far fa-eye",
      title: "Our Vision",
      desc: "We aim to create sustainable, harmonious designs that enhance life through architecture.",
    },
  ],
  projects_title: "Some of our most recent projects.",
  projects: [
    {
      title: "Doctor's Villa",
      category: "Category",
      img: "images/projects/2.webp",
    },
    {
      title: "Doctor's Villa",
      category: "Category",
      img: "images/projects/4.webp",
    },
    {
      title: "Bunglow at Ahmedabad",
      category: "Category",
      img: "images/projects/3.webp",
    },
    {
      title: "Bunglow at Ahmedabad",
      category: "Category",
      img: "images/projects/5.webp",
    },
    {
      title: "Bunglow at Sarkhej",
      category: "Category",
      img: "images/projects/1.webp",
    },
    {
      title: "Bunglow at Sarkhej",
      category: "Category",
      img: "images/projects/6.webp",
    },
    {
      title: "Bunglow at Anand",
      category: "Category",
      img: "images/projects/7.webp",
    },
    {
      title: "Bunglow at Anand",
      category: "Category",
      img: "images/projects/8.webp",
    },
  ],
  get_in_touch: "Get in touch with us",
};

// Contact Us data
export const contactData = {
  desc: "We launch our new website soon. For Customer Support and Query, Get in touch with us.",
  support_note: "For Customer Support, Send us a note.",
  cards: [
    {
      icon: "fas fa-map-marker-alt",
      title: "VISIT US",
      lines: [
        "PLOT NO. 182-1/2, SAVA AAYUSH HOSPITAL JORAWAR NAGAR. SURENDRANAGAR, GUJARAT, INDIA.",
      ],
    },
    // {
    //   icon: "fas fa-phone-alt",
    //   title: "CALL US NOW",
    //   lines: ["Phone: +91-94287-12004"],
    // },
    {
      icon: "fas fa-clock",
      title: "BUSINESS HOURS",
      lines: ["(8 am – 6 pm)"],
    },
    {
      icon: "fas fa-envelope",
      title: "INQUIRIES",
      lines: ["Info@tonnageproductscompany.com"],
    },
  ],

  form: {
    nameLable: "What is Your Name:",
    namePlaceholder: "Enter Your Name",

    emailLable: "Your Email Address:",
    emailPlaceholder: "Enter Your Email",

    messageLable: "How can I Help you?",
    messagePlaceholder: "Enter Your Query",

    submitButton: "Send Message",
  },
  contact: "(+91) 11-2142-566",
};
