import React, { useEffect, useRef, useState } from "react";
import { landingPageData } from "../config/dataConfig";
import { toast } from "react-toastify";
import { postData } from "../utils/apiCall";

const SubscribeForm = ({ isDark = false, isPrimary = false }) => {
  const emailRef = useRef();

  const [mail, setMail] = useState("");
  const [sendingMail, setSendingMail] = useState(false);

  const onSubscribe = async (e) => {
    e.preventDefault();
    setSendingMail(true);
    let body = {
      text: `Subscriber : ${mail}
    
    `,
      html: `Subscriber : <b> ${mail} </b> <br/>
     
     `,
      receiver: "Info@tonnageproductscompany.com",
      subject: "New subscription from tonnageproductscompany.com",
    };

    const params = new URLSearchParams();

    body.text && params.append("text", body.text);
    body.html && params.append("html", body.html);
    body.receiver && params.append("receiver", body.receiver);
    body.subject && params.append("subject", body.subject);

    const res = await postData("/send-mail", params);

    if (res.success) {
      setSendingMail(false);
      toast.success("Email sent successfully!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setMail("");
    } else {
      setSendingMail(false);
      toast.error("Something went wrong. try again!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    // emailjs
    //   .sendForm(
    //     emailjsConfig.serviceId,
    //     emailjsConfig.templateId,
    //     form.current,
    //     emailjsConfig.userId
    //   )
    //   .then(
    //     (result) => {
    //       document.getElementById("contact-form").reset();
    //       toast.success("Message sent successfully!", {
    //         position: "top-right",
    //         autoClose: 5000,
    //         hideProgressBar: false,
    //         closeOnClick: true,
    //         pauseOnHover: true,
    //         draggable: true,
    //         progress: undefined,
    //         theme: "light",
    //       });
    //       console.log(result.text);
    //       setSendingMail(false);
    //     },
    //     (error) => {
    //       toast.error("Something went wrong!", {
    //         position: "top-right",
    //         autoClose: 5000,
    //         hideProgressBar: false,
    //         closeOnClick: true,
    //         pauseOnHover: true,
    //         draggable: true,
    //         progress: undefined,
    //         theme: "light",
    //       });
    //       console.log(error.text);
    //       setSendingMail(false);
    //     }
    //   );
  };

  return (
    <div className="subscribe-form">
      <form className={isDark ? "form-dark" : ""}>
        <div className="input-group">
          <input
            type="email"
            id="mce-EMAIL"
            name="EMAIL"
            ref={emailRef}
            className="form-control border-2 required"
            required
            placeholder={landingPageData.notify.placeholder}
            value={mail}
            onChange={(e) => setMail(e.target.value)}
          />
          <button
            id="subscribe-form-submit"
            name="subscribe-form-submit"
            className={
              "btn px-4 shadow-none " + (isPrimary ? "btn-dark" : "btn-primary")
            }
            onClick={onSubscribe}
          >
            <span className="d-none d-sm-block">
              {sendingMail ? (
                <i
                  role="status"
                  aria-hidden="true"
                  className="spinner-border spinner-border-sm"
                ></i>
              ) : (
                <>{landingPageData.notify.button}</>
              )}
            </span>
            <span className="text-4 d-block d-sm-none">
              <i className="fas fa-arrow-right" />
            </span>
          </button>
        </div>
      </form>
      <div className="subscribe-form-result mt-3" />
    </div>
  );
};

export default SubscribeForm;
